.procureContainer{
    margin: auto;
    width: 70%;
}

.procureContainer h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-left: 2%;
}

.procureColumn{
    width: 50%;
    float: left;
    padding: 20px;
}

.procureColumn a{
    text-decoration: none;
}

.procureCard{
    background: #FFF;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
    height: 350px;
}

.procureCard p{
    color: #0C91BB;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 20px;
    margin-top: 0%;
    text-decoration: none;
}

.procureCard img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 50% 50%;
    padding-bottom: 10px;
}

.procureImgContainer {
    position: relative;
    width: 100%;
}

.procureImage {
    display: block;
    width: 100%;
    height: auto;
}

.imageOverlay {
    position: absolute; 
    top: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    height: 96%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
}

.imageOverlay p{
    margin-top: 25%;
    color: #FFF;
}

.procureImgContainer:hover .imageOverlay {
    opacity: 1;
}

@media screen and (max-width: 600px) {
    .procureContainer{
        margin: auto;
        width: 90%;
        margin-top: 160%;
    }
    
    .procureContainer h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-left: 2%;
    }
    
    .procureColumn{
        width: 100%;
        float: left;
        padding: 10px;
    }
    
    .procureCard{
        background: #FFF;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
        height: 290px;
    }
    
    .procureCard p{
        color: #0C91BB;
        font-family: 'Roboto', sans-serif;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 20px;
        margin-top: 0%;
    }
    
    .procureCard img{
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: 50% 50%;
        padding-bottom: 10px;
    }
}