@import url('https://fonts.googleapis.com/css2?family=Lateef:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

* {
    box-sizing: border-box;
}

.slideshowHome-container {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: scroll;
    background-color: #ccc
}

/* .slideHome {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
} */

.slideHome {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease;
}

@keyframes slideIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.slideHome img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    animation: slideIn 0.5s ease forwards;
}

.slideHome.active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.dotHome-container {
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.dotHome {
    width: 15px;
    height: 15px;
    background-color: #FFF;
    border-radius: 50%;
    display: inline-block;
    margin: 0 15px;
    cursor: pointer;
}

.dotHome.active {
    background-color: #0C91BB;
}

.slideHomeContent{
    font-family: 'Lateef', serif;
    height: 100%;
    width: 100%;
    bottom: 0%;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
}

.slideHomeContentText{
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.slideHomeContentText h1{
    color: #FFF;
    font-family: 'Lateef', serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 10px;
    width: 100%;
    margin-top: -8%;
}

.slideHomeContentText h3{
    color: #FFF;
    font-family: 'Lateef', serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 5.425px;
    margin-top: -3%;
}

.slideHomeContentText h5{
    color: #FFF;
    text-align: center;
    font-family: 'Lateef', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 1000px;
    flex-shrink: 0;
    margin-left: 0%;
    margin-top: -2%;
}

.slideHomeContentText button{
    border-radius: 10px;
    background: #0C91BB;
    width: 170px;
    height: 45px;
    flex-shrink: 0;
    border: none;
    color: #FFF;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.1px;
    margin-top: -10%;
}

.slideHomeContentText button:hover{
    background: #010C6C;
}

.statisticsContainer{
    max-width: 70%;
    margin: auto;
    margin-top: 1%;
}

.statistics {
    float: left;
    width: 33.33%;
    padding: 0px;
}

.centerStatisticCard{
    border-left: 1px solid #FFF;
    border-right: 1px solid #FFF;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.statistics h2{
    color: #FFF;
    font-family: 'Lateef', serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 16.45px;
    margin-bottom: -5%;
    text-align: center;
}

.statistics p{
    color: #FFF;
    font-family: 'Lateef', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.19px;
}

.floating-counter {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.counter-text {
    color: #FFF;
    font-family: 'Lateef', serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 16.45px;
    margin-bottom: -5%;
    text-align: center;
}
  
@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
}
  
.bounce-animation {
    animation: bounce 2s alternate;
}

@media screen and (max-width: 600px) {
    .slideshow-container {
        position: relative;
        width: 100%;
        height: 10vh;
        overflow: hidden;
    }

    .slideContent{
        font-family: 'Lateef', serif;
        height: 70vh;
        position: relative;
        text-align: center;
    }
    
    .slideContentText{
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0%;
        -ms-transform: translate(-100%, -100%);
        transform: translate(-20%, -50%);
    }
    
    .slideContentText h1{
        color: #FFF;
        font-family: 'Lateef', serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 10.73px;
        width: 100%;
    }
    
    .slideContentText h3{
        color: #FFF;
        font-family: 'Lateef', serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 5.425px;
    }
    
    .slideContentText h5{
        color: #FFF;
        text-align: center;
        font-family: 'Lateef', serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 400px;
        flex-shrink: 0;
        margin-left: 2.5%;
    }
    
    .slideContentText button{
        border-radius: 10px;
        background: #0C91BB;
        width: 120px;
        height: 45px;
        flex-shrink: 0;
        border: none;
        color: #FFF;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 2px;
    }
    
    .slideContentText button:hover{
        background: #010C6C;
    }
    
    .statisticsContainer{
        max-width: 100%;
        margin: auto;
        margin-top: 10%;
    }
    
    .statistics {
        float: left;
        width: 33.33%;
        padding: 0px;
    }
    
    .centerStatisticCard{
        border-left: 1px solid #FFF;
        border-right: 1px solid #FFF;
    }
    
    .row:after {
        content: "";
        display: table;
        clear: both;
    }
    
    .statistics h2{
        color: #FFF;
        font-family: 'Lateef', serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 16.45px;
        margin-bottom: -5%;
        text-align: center;
    }
    
    .statistics p{
        color: #FFF;
        font-family: 'Lateef', serif;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.19px;
    }
    
    .floating-counter {
        display: flex;
        justify-content: center;
        align-items: center;
    }
      
    .counter-text {
        color: #FFF;
        font-family: 'Lateef', serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 16.45px;
        margin-bottom: -10%;
        text-align: center;
    }
}