.projectContainer{
    max-width: 90%;
    margin: auto;
    padding: 20px;
}

.projectColumn{
    width: 25%;
    float: left;
    padding: 5px;
}

.projectCard{
    background: linear-gradient(125deg, #010C6C 0%, #042E80 24.92%, #064A91 45.26%, #085E9D 60.01%, #0972A8 74.76%, #0B82B2 86.45%, #0C91BB 97.64%);
    box-shadow: 0px 10px 10px 5px rgba(0, 0, 0, 0.25);
    width: 270px;
    height: 245px;
    flex-shrink: 0;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.projectCard h1{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px; /* 225% */
    padding-top: 20%;
}

.projectCard h2{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px; /* 225% */
    margin-top: -8%;
}

.projectCard p{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px; /* 225% */
    margin-top: -5%;
}

.projectContainer span{
    color: #000;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 45px; /* 264.706% */
}

.projectContainer h3{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media screen and (max-width: 600px) {
    .projectContainer{
        max-width: 100%;
        margin: auto;
        padding: 20px;
    }

    .projectColumn{
        width: 50%;
        float: left;
        padding: 5px;
    }

    .projectCard{
        background: #FFF;
        box-shadow: 0px 10px 10px 5px rgba(0, 0, 0, 0.25);
        width: 170px;
        height: 220px;
        flex-shrink: 0;
        display: block;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .projectCard h1{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 45px; /* 225% */
        padding-top: 20%;
    }
    
    .projectCard h2{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 45px; /* 225% */
        margin-top: -8%;
    }
    
    .projectCard p{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 45px; /* 225% */
        margin-top: -5%;
    }
    
    .projectContainer span{
        color: #000;
        text-align: justify;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 45px; /* 264.706% */
        margin-top: 5%;
    }
    
    .projectContainer h3{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}