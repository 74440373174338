.qualityDetailsCont{
    margin: auto;
}

.qualityDetailsCont h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.qualityDetailsCont p{
    color: #000;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 250% */
    width: 70%;
    margin: auto;
}

.qualityDetailsBackground { 
    height: 300px;
    position: relative;
    background-image: url('../../assets/Rectangle 120.png');
    width: 90%;
    margin: auto;
    margin-top: 2%;
}

.qualityColumnCont{
    padding-top: 10%;
}

.qualityColumn{
    float: left;
    width: 50%;
    padding: 60px;
}

.qualityColumn .qualityCard{
    background: #010C6C;
    padding: 30px;
    color: #FFF;
}

.qualityCard:hover{
    background-color: #FFF;
    color: #010C6C;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.qualityColumn .qualityCard p{
    color: inherit;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px; /* 225% */
    margin-left: 2%;
}

.qualityColumn .qualityCard ul{
    margin-top: -0%;
}

.qualityColumn .qualityCard ul li{
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px; /* 194.444% */
    margin-bottom: 5px;
    width: 90%;
}

.qualityCertifiedImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
}

@media screen and (max-width: 600px) {
    .qualityDetailsCont{
        margin: auto;
    }
    
    .qualityDetailsCont h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }
    
    .qualityDetailsCont p{
        color: #000;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 250% */
        width: 90%;
        margin: auto;
    }
    
    .qualityDetailsBackground { 
        height: 400px;
        position: relative;
        background-image: url('https://s3-alpha-sig.figma.com/img/b4c0/19a2/cd1fa3f0f89d8eaf787cea87dd7b7d6a?Expires=1694995200&Signature=ZRyDJnXo1WUqb~6fIWhP80CDbvRtBURhzgDPf55cPHnsE8~cX~K5ATF6qdzfcgBsmLF9NePXxVDZhB5-zxezDFmPsj9gSngoMtQk3anZkR1LS21x42arZDLkAPOlMTxhK4z9rjxXl8CyRze8YgJ5Vjl-fwjs-mX4kOFySCM5t1haLSonItD3eZkDb0CZ0orAz7e8y4AUErwVOc55RLWRICJXLTEkLRsuvicfdzyOto2xRHD5zRswrp1XkQs2EsE-urbJ9cPb2GoyhbL9YbkrXclgd3wiOyQBTIoWxHNUQ5MgjW81Bgz~j29t4Vvyl~hUmG-IVGBXB57g3PpU-qHrfA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4');
        width: 90%;
        margin: auto;
        margin-top: 2%;
    }
    
    .qualityColumnCont{
        padding-top: 10%;
    }
    
    .qualityColumn{
        float: left;
        width: 100%;
        padding: 20px;
    }
    
    .qualityColumn .qualityCard{
        background: #010C6C;
        padding: 30px;
        color: #FFF;
    }
    
    .qualityColumn .qualityCard p{
        color: #FFF;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px; /* 225% */
        margin-left: 2%;
    }
    
    .qualityColumn .qualityCard ul{
        margin-top: -0%;
    }
    
    .qualityColumn .qualityCard ul li{
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px; /* 194.444% */
        margin-bottom: 5px;
        width: 100%;
    }
    
    .qualityCertifiedImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 20%;
    }
}