@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

.header {
    background-color: #FFF;
    padding: 40px 10px;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.header img{
    float: left;
    position: absolute;
    left: 0%;
    top: 0%;
    transform: translate(-50%, -50%);
}

.header a{
    float: left;
    color: black;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    font-size: 18px; 
    line-height: 25px;
    border-radius: 4px;
    color: #0C91BB;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.header a:hover {
    color: #010C6C;
    border-bottom: 2px solid #010C6C;
}

.header-right {
    float: right;
    position: absolute;
    top: 55%;
    left: 95%;
    transform: translate(-50%, -50%);
}

.header-centered {
    float: none;
    position: absolute;
    top: 55%;
    left: 49%;
    transform: translate(-50%, -50%);
}

.dropdown-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #0C91BB;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dropdown-button:hover{
    color: #010C6C;
}

.dropdown-content {
    display: none;
    position: fixed;
    background-color: #333;
    width: 15%;
    z-index: 10;
    margin-top: 2%;
}

.dropdown-content a {
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    float: left;
    word-break: break-all;
    z-index: 10;
}

.dropdown:hover .dropdown-content {
    display: block;
    z-index: 10;
}

.toggleSide{
    float: right;
    padding-top: 4%;
}

.MobileMenuButton {
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: white;
}

.close{
    color: #000;
    margin-top: 5%;
    float: left;
    margin-left: 5%;
    font-size: 40px;
    color: #0C91BB;
}

@media screen and (max-width: 600px) {
    .header {
        background-color: #FFF;
        padding: 40px 10px;
        position: relative;
        overflow: hidden;
        width: 100%;
        display: none;
    }
}