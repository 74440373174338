.solutionCol{
    width: 50%;
    float: left;
    padding: 10px;
    margin-top: 7%;
}

.servicesImgCard {
    box-shadow: -12px -31px 0px 10px #010C6C, -40px -60px 0px 15px #0C91BB;
    padding: 16px;
    text-align: center;
    background-color: #f1f1f1;
    width: 80%;
    margin: auto;
    height: 600px;
    background-image: url("../../assets/Rectangle 86.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.servicesImgCont{
    border: 1px solid #0C91BB;
    height: 570px;
}

.textSolutionCol{
    margin-left: 20%;
    margin-top: -10%;
}

.solutionCol h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.solutionCol p{
    color: #17252A;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 250% */
}

.solutionsBody {
    width: 100%;
    background-color: #F6F6F6;
    padding: 20px;
}

.solutionPolygon {
    margin-right: -13%;
    margin-top: 0%;
    position: relative;
    text-align: center;
}

.solutionsPolygonBottom{
    margin-right: -18%;
    margin-top: 30%;
    position: relative;
    text-align: center;
}

.solutionsBody h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.polygonText{
    position: absolute;
    top: 46%;
    left: 48%;
    transform: translate(-50%, -50%);
}

.solutionPolygon .polygonTextTop{
    position: absolute;
    top: 35%;
    left: 48%;
    transform: translate(-50%, -50%);
}

.polygonText, .polygonTextTop p {
    font-weight: 400;
    text-transform: uppercase;
    z-index: 5;
    font-family: "Anonymous Pro";
    padding: 0;
    margin: 0;
    color: #010C6C;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.solutionPolyBody{
    padding-bottom: 2%;
    display: inline-flex;
    max-width: 20%;
    margin: auto;
    margin-left: 20%;
    margin-top: 1%;
}

.solutionsBody img{
    width: 200px;
    height: 220px;
}

.floating-text {
    font-size: 24px;
    color: #3498db;
    animation: floatText 3s ease;
}

@keyframes floatText {
    0%, 100% {
        transform: translateX(-100px);
    }
    50% {
        transform: translateX(20px);
    }
}

@media screen and (max-width: 600px) {
    .solutionCol{
        width: 100%;
        float: left;
        padding: 10px;
        margin-top: 7%;
    }

    .servicesImgCard {
        box-shadow: -12px -31px 0px 10px #010C6C, -40px -60px 0px 15px #0C91BB;
        padding: 16px;
        text-align: center;
        background-color: #f1f1f1;
        width: 80%;
        margin-left: 15%;
        height: 400px;
        background-image: url("https://s3-alpha-sig.figma.com/img/b3a8/19bc/c15b3af208e3acd756b71b1bbd453f86?Expires=1694995200&Signature=e8LKsXIaWtoz2i4dmW0R5MoW62R4QwRhIstKG9I~QAam-upd54TdxwV44cJLoSr~ARtfPU9CfeUjO3I-XPtKPzH0tIgzgXNyvARfDF9VS1XN2in~N8Uwfz~auoKoH0M-y8n5Loxs5zRiHcVn~cVYyXCx3pKmEnPbSNu9CkuwYwMEEfyEEUnXdHjPgNe7bST~B~vdIPP9FtLLqF1W3ubModrXRQchrCcsO-IkGP4wS9XqHPZNnnF3ZU6YD3vDKY2G7NqR6e9oB6Qbh2SYt48KDyoC7aYwDU1H7fZLUgfYvFqfOn3dIpBY58x2k-O9l3xiZ2~OAoTfXVtkrX~HVIB-vA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .servicesImgCont{
        border: 1px solid #0C91BB;
        height: 370px;
    }

    .textSolutionCol{
        margin-left: 5%;
        margin-top: -5%;
    }

    .solutionCol h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .solutionCol p{
        color: #17252A;
        text-align: justify;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 250% */
        width: 90%;
        margin-bottom: 10%;
    }

    .solutionsBody {
        width: 100%;
        background-color: #F6F6F6;
        padding: 20px;
    }

    .solutionPolygon {
        margin-right: -18%;
        margin-top: 0%;
        position: relative;
        text-align: center;
    }

    .solutionsPolygonBottom{
        margin-right: -18%;
        margin-top: 22%;
        position: relative;
        text-align: center;
    }

    .solutionsBody h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }

    .polygonText{
        position: absolute;
        top: 46%;
        left: 48%;
        transform: translate(-50%, -50%);
    }

    .solutionPolygon .polygonTextTop{
        position: absolute;
        top: 29%;
        left: 48%;
        transform: translate(-50%, -50%);
    }

    .polygonText, .polygonTextTop p {
        font-weight: 400;
        text-transform: uppercase;
        z-index: 5;
        font-family: "Anonymous Pro";
        padding: 0;
        margin: 0;
        color: #010C6C;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .solutionPolyBody{
        padding-bottom: 2%;
        display: inline-flex;
        max-width: 60%;
        margin: auto;
        margin-left: 0%;
        margin-top: 1%;
    }

    .solutionsBody img{
        width: 110px;
        height: 120px;
    }
}