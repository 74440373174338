.productsContainer{
    overflow: hidden;
}

.productsSpecialtyContainer{
    margin-top: 0%;
    overflow: hidden;
}

.productsColumnsContainer{
    margin-top: 0%;
    overflow: hidden;
}

.productsContainer h1{
    color: #17252A;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 0%;
}

.productsSpecialtyContainer h1{
    color: #17252A;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 0%;
}

.productsColumnsContainer h1{
    color: #17252A;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 0%;
}

.proSpacing{
    height: 0px;
}

.topSpacing{
    margin-top: 2%;
    height: 1100px;
}

.topSpacingStruc{
    margin-top: 0%;
    height: 580px;
}

.topSpacingStructural{
    margin-top: 5%;
    height: 0px;
}

.topSpecSpacing{
    margin-top: 0%;
    height: 1450px;
}

.topColumnSpacing{
    margin-top: 0%;
    height: 550px;
}

.productColumn {
    float: left;
    width: 33.33%;
    padding: 10px;
    margin-top: -28%;
    margin-bottom: 18%;
}

.productStructsColumn{
    float: left;
    width: 33.33%;
    padding: 10px;
    margin-top: -20%;
    margin-bottom: 18%;
}

.structuralColumn{
    float: left;
    width: 50%;
    padding: 0px;
}

.productColumn img{
    width: 100%;
}

.productTextContainer {
    position: relative;
    text-align: center;
    color: white;
}

.productTextContainer .regularImage{
    width: 200px;
    height: 260px;
    flex-shrink: 0;
    object-fit: cover;
    object-position: 50% 50%;
    margin-top: 20%;
}

.productTextContainer .regularStructuralImage{
    width: 200px;
    height: 250px;
    flex-shrink: 0;
    object-fit: cover;
    object-position: 50% 50%;
    margin-top: 40%;
}

.centerStructuralImage{
    width: 200px;
    height: 310px;
    flex-shrink: 0;
    object-fit: cover;
    object-position: 50% 50%;
    padding-bottom: 10px;
    margin-bottom: 0%;
    margin-top: 25%;
}

.productTextStruc {
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 250px;
    background-color: rgba(0,0,0, 0.4);
}

.productTextStruc p{
    margin-top: 45%;
    line-height: 25px;
}

.productCenteredTextStruc {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 300px;
    background-color: rgba(0,0,0, 0.4);
}

.productCenteredTextStruc p{
    margin-top: 45%;
    line-height: 25px;
}

.productCenteredText {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 260px;
    background-color: rgba(0,0,0, 0.4);
}

.productCenteredText p{
    margin-top: 45%;
    line-height: 25px;
}

.productCenteredImageCont {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 290px;
    background-color: rgba(0,0,0, 0.4);
}

.productCenteredImageCont p{
    margin-top: 40%;
    line-height: 30px;
}

.centeredImageContBottom {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 290px;
    background-color: rgba(0,0,0, 0.4);
}

.centeredImageContBottom p{
    margin-top: 50%;
    line-height: 25px;
}

.productTextContainer .centeredProductImage{
    width: 200px;
    height: 298px;
    flex-shrink: 0;
    object-fit: cover;
    object-position: 50% 50%;
    padding-bottom: 10px;
    margin-bottom: 20%;
}

.productTextContainer .centeredProductImageBottom{
    width: 200px;
    height: 298px;
    flex-shrink: 0;
    object-fit: cover;
    object-position: 50% 50%;
    padding-bottom: 10px;
    margin-top: 20%;
}

.productContainerBG { 
    height: 173px;
    position: relative;
    margin-top: 17%;
    background-image: url("../../assets/productbg1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.productStructuralBG{
    background-image: url("../../assets/productbg3.png");
    height: 173px;
    position: relative;
    margin-top: 17%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.productStructuralElectricBG{
    background-image: url("../../assets/productbg1.png");
    height: 173px;
    position: relative;
    margin-top: 8%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.productValvesBG{
    background-image: url("../../assets/productbg2.png");
    height: 173px;
    position: relative;
    margin-top: 17%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.productSpecialtyBG{
    background-image: url("../../assets/productbg2.png");
    height: 173px;
    position: relative;
    margin-top: 17%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.productContainerStructBG { 
    height: 173px;
    position: relative;
    margin-top: 17%;
    background-image: url("../../assets/productbg2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.productContainerCenter{
    max-width: 50%;
    margin: auto;
}


.structuraltable{
    position: absolute;
    margin-top: 5%;
    width: 100%;
}

.table{
    position: absolute;
    margin-top: 20%;
    width: 100%;
}

.tableColCont {
    border-collapse: collapse;
    width: 80%;
    margin: auto;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.tableColCont p{
    margin-left: 20px;
    font-weight: bold;
}

.tableCol{
    width: 33.333%;
    float: left;
    padding: 0px;
}

.tableCol4{
    width: 25%;
    float: left;
    padding: 0px;
}

.tableCol h4{
    margin-left: 20px;
    margin-top: 5px;
}

.tableCol4 h4{
    margin-left: 20px;
    margin-top: 5px;
}

.tableCol li{
    padding: 10px;
}

.tableCol4 li{
    padding: 10px;
}

.structuralColumn li{
    padding: 10px;
}

.pagination{
    margin-top: 60%;
    width: 20%;
    margin-left: 70%;
    margin-right: 10%;
}

.pagination a {
    color: black;
    float: left;
    padding: 8px 6px;
    text-decoration: none;
    transition: background-color .3s;
}

.pagination a.active {
    color: #000;
    text-align: justify;
    font-family: 'DM Sans', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pagination a.notactive{
    color: rgba(0, 0, 0, 0.50);
    text-align: justify;
    font-family: 'DM Sans', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

@media screen and (max-width: 600px) {
    .productsContainer h1{
        color: #17252A;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-top: 3%;
    }

    .productsSpecialtyContainer h1{
        color: #17252A;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-top: 0%;
    }

    .productsColumnsContainer h1{
        color: #17252A;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-top: 0%;
    }

    .proSpacing{
        height: 500px;
        margin-top: -0%;
    }

    .topSpacing{
        margin-top: 10%;
        height: 1100px;
    }

    .topSpacingStruc{
        margin-top: 0%;
        height: 1800px;
    }

    .topSpecSpacing{
        margin-top: 0%;
        height: 2000px;
    }

    .topColumnSpacing{
        margin-top: 10%;
        height: 2450px;
    }

    .productColumn {
        float: left;
        width: 33.33%;
        padding: 10px;
        margin-top: -8%;
        margin-bottom: 18%;
    }

    .structuralColumn{
        float: left;
        width: 100%;
        padding: 0px;
    }

    .productColumn img{
        width: 100%;
        display: none;
    }

    .productTextContainer {
        position: relative;
        text-align: center;
        color: white;
        display: none;
    }

    .productTextContainer .regularImage{
        width: 100px;
        height: 260px;
        flex-shrink: 0;
        object-fit: cover;
        object-position: 50% 50%;
        margin-top: 20%;
    }

    .productCenteredText {
        position: absolute;
        top: 56%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 260px;
        background-color: rgba(0,0,0, 0.4);
    }

    .productCenteredText p{
        margin-top: 45%;
        line-height: 25px;
    }

    .productCenteredImageCont {
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
        height: 290px;
        background-color: rgba(0,0,0, 0.4);
    }

    .productCenteredImageCont p{
        margin-top: 40%;
        line-height: 30px;
    }

    .centeredImageContBottom {
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 290px;
        background-color: rgba(0,0,0, 0.4);
    }

    .centeredImageContBottom p{
        margin-top: 50%;
        line-height: 25px;
    }

    .productTextContainer .centeredProductImage{
        width: 100px;
        height: 298px;
        flex-shrink: 0;
        object-fit: cover;
        object-position: 50% 50%;
        padding-bottom: 10px;
        margin-bottom: 20%;
    }

    .productTextContainer .centeredProductImageBottom{
        width: 200px;
        height: 298px;
        flex-shrink: 0;
        object-fit: cover;
        object-position: 50% 50%;
        padding-bottom: 10px;
        margin-top: 20%;
    }

    .productContainerBG { 
        height: 173px;
        position: relative;
        margin-top: 17%;
        background-image: url("https://s3-alpha-sig.figma.com/img/966d/5661/8e8f9c759ccf8bed408e36e9ee313152?Expires=1694995200&Signature=q-1VhHLMWiovVZcaUOxItI-Kd1943pR~sztZCELRW4FykQeTsmRp9nklFgMx-2qFtCiog94goR8nztb8BZDaTPIhofRWhf7wME8MQ4KabX6hgOhUjaYrKPbdPUeFOLEk9qx7EqJ17ringl~CJvPWasACe3mbQcs5CCDFHyZBW2MNVPS7DaS9i06yUZRRo5jHRx-vL~XMHAOD9HZEg-Mfbfo2JnJDZdU4wxAk96k8DZPELHd6xmHw13EJRGWlxofwHzHQ4lZbXajISF-4VG55DaxLp9ChBehFjwSQapRjrNwJ6j55OijJbx27nrTz2vfdP5zGr6LqZW2tytJf~FY9gA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
    }

    .productContainerStructBG { 
        height: 173px;
        position: relative;
        margin-top: 17%;
        background-image: url("https://s3-alpha-sig.figma.com/img/5c23/9c3f/04509856be00481f52e34fd4bbe531f4?Expires=1695600000&Signature=QixdF5OBmZHa4eGldvvqWSJUgGKft1ZoqjdcLL1JmU8L8gHj6QLtHbDytmSOKsUGKytK1jiSqF0PYze9lU1UNLhC~bmcqJQ-Cr9YEc5jAi1IaygV1ssY4dIwjKVQxtFED2LiUGcyZWymAMzrei3F4icAKtOTW-lwjHZWWq63Bfksx31vCG0Z6-CjTrhJglUP6ddgIa4dtDGx0nuSEcyi~KV3Y318sGeruif6~cZmwjEMjWJkmUcIiJDFM9ZGaEprWSy5UZjPB2LEgTQNQMQ8tq38ggxBepSq8gWSRqfJEfLvLDzdlNy4SOcRc--c8tek~EgMVlb04lhmPT6y8YC3lA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
    }

    .productContainerCenter{
        max-width: 100%;
        margin: auto;
    }

    .table{
        position: absolute;
        margin-top: 0%;
        width: 95%;
        margin-left: 2.5%;
    }

    .tableColCont {
        border-collapse: collapse;
        width: 100%;
        margin: auto;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .tableColCont h3{
        display: none;
    }

    .tableCol{
        width: 100%;
        float: left;
        padding: 0px;
    }

    .tableCol li{
        padding: 10px;
    }

    .structuralColumn li{
        padding: 10px;
    }

    .pagination a {
        color: black;
        float: left;
        padding: 8px 6px;
        text-decoration: none;
        transition: background-color .3s;
        margin-top: 5%;
    }
    
    .pagination a.active {
        color: #000;
        text-align: justify;
        font-family: 'DM Sans', serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .pagination a.notactive{
        color: rgba(0, 0, 0, 0.50);
        text-align: justify;
        font-family: 'DM Sans', serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .pagination a:hover:not(.active) {background-color: #ddd;}
}