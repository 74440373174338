html {
   scroll-behavior: smooth;
}

.footer {
   left: 0;
   bottom: 0;
   width: 100%;
   background: url("../../assets/pexels-pixabay-372796 1.png");
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   text-align: center;
   padding: 0px;
   height: 550px;
   overflow: hidden;
   margin-top: 5%;
}

.footerBGOne{
   background: rgba(1,12,108, 0.4);
   width: 100%;
   height: 100%;
}

.footerBGTwo{
   background-color: rgba(163,163,163, 0.4);
   width: 95%;
   margin: auto;
   height: 450px;
}

.footerSpace{
   padding-top: 4%;
}

* {
   box-sizing: border-box;
}

.footerColumn {
   float: left;
   height: 250px;
   margin-top: 3%;
}

.abt{
   width: 30%;
}

.links{
   width: 20%;
}

.cntc{
   width: 30%;
}

.footerColumn img{
   width: 40%;
   margin-right: 35%;
}

.space{
   margin-left: 50%;
}

.spaceServices{
   margin-left: 20%;
}

.footerColumn h3{
   color: #FFF;
   text-align: left;
   font-family: 'DM Sans', serif;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px; /* 175% */
   margin-left: 15%;
   width: 100%;
   margin-top: -0%;
}

.footerColumn h2{
   color: #FFF;
   font-family: 'Roboto', sans-serif;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   text-align: left;
}

.footerColumn a{
   color: #FFF;
   font-family: 'Roboto', sans-serif;
   font-size: 14px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   display: block;
   text-align: left;
   margin-left: 0%;
   padding-bottom: 10px;
   text-decoration: none;
}

.footerColumn p{
   color: #FFF;
   font-family: 'Roboto', sans-serif;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   text-align: left;
}

.footerColumn p svg{
   float: left;
   margin-right: 10px;
}

.footerColumn p span{
   margin-left: 70px;
}

.footerColumn p div{
   margin-left: 80px;
   padding-top: -10px;
}

.footerNav {
   position: relative;
   overflow: hidden;
   background-color: #FF000000;
   border-bottom: 1px solid #FFF;
   padding: 20px;
   max-width: 45%;
   margin: auto;
}
 
.footerNav a {
   float: left;
   color: #000;
   text-align: center;
   padding: 24px 16px;
   text-decoration: none;
   font-size: 17px;
   text-decoration: none;
}
 
.footerNav-centered {
   float: none;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.reservedRights{
   color: #FFF;
}

.reservedRights p{
   color: #FFF;
   text-align: center;
   font-family: 'Roboto', sans-serif;
   font-size: 14px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
}

@media screen and (max-width: 600px) {
   .footer {
      left: 0;
      bottom: 0;
      width: 100%;
      background: url("https://s3-alpha-sig.figma.com/img/aed7/a2fc/d986f2b6e07400e9bba6848213175457?Expires=1695600000&Signature=fkK-UaR2zji4oJVmMMX4wkj3y03mFbZKOAEI5hWH9jSDOsujtc5YyIZS2M1gxmiMOo5~ctt7~7TG7pHEEjTnrkNvvH6sOqYrPioEg-QKg3BhbYlo2kNvp0Vx3yYIbC2~b2XlZ0x1cWFiHsyqFMSBoWkMJfDEA8Uy-c16NFIV3oQaCiWvwT1MbRyT1EOlHbbzSpUvfcKywPvSNT5aIxk5ZeGb~BUYAdyKXeXO1cYp3ddgZ82XPL2o24D6t90B~dJAR2TMXw7YD2WJtuJMqL6l~o~xJAN3IO261EkUU9n9-WtZA79EltxAfNo4IgW6kheeKr9kgMf1Cqabi7p4bviDuQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;
      padding: 0px;
      height: 750px;
      overflow: hidden;
   }
   
   .footerBGOne{
      background: rgba(1,12,108, 0.4);
      width: 100%;
      height: 100%;
   }
   
   .footerBGTwo{
      background-color: rgba(163,163,163, 0.4);
      width: 90%;
      margin: auto;
      height: 750px;
   }
   
   .footerSpace{
      padding-top: 4%;
   }

   .footerColumn {
      float: left;
      height: 100px;
      margin-top: 3%;
   }
   
   .abt{
      width: 100%;
      height: 300px;
   }
   
   .links{
      width: 50%;
      margin-top: -25%;
      height: 300px;
   }
   
   .cntc{
      width: 100%;
      margin-top: -30%;
      margin-left: 10%;
      height: 180px;
   }
   
   .footerColumn img{
      width: 40%;
      margin-left: -50%;
   }
   
   .space{
      margin-left: 20%;
   }
   
   .footerColumn h3{
      color: #FFF;
      text-align: left;
      font-family: 'DM Sans', serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 175% */
      margin-left: 10%;
      width: 80%;
   }
   
   .footerColumn h2{
      color: #FFF;
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left;
   }
   
   .footerColumn a{
      color: #FFF;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: block;
      text-align: left;
      margin-left: 0%;
      padding-bottom: 10px;
   }
   
   .footerColumn p{
      color: #FFF;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
   }
   
   .footerColumn p svg{
      float: left;
      margin-right: 10px;
   }
   
   .footerColumn p span{
      margin-left: 55px;
   }
   
   .footerNav {
      position: relative;
      overflow: hidden;
      background-color: #FF000000;
      border-bottom: 1px solid #FFF;
      padding: 30px;
      max-width: 100%;
      margin: auto;
      margin-top: -30%;
   }
    
   .footerNav a {
      float: left;
      color: #000;
      text-align: center;
      padding: 10px 10px;
      text-decoration: none;
      font-size: 17px;
   }
    
   .footerNav-centered {
      float: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
   
   .reservedRights{
      color: #FFF;
   }
   
   .reservedRights p{
      color: #FFF;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
   }
}