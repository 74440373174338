@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    box-sizing: border-box;
}

.industriesContainer{
    background: rgba(191, 198, 255, 0.1);
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

.industriesContainer h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.industries{
    float: left;
    width: 33.333%;
    padding: 10px;
}

.industries img{
    width: 100%;
}

.industries p{
    color: #0C91BB;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .industries{
        float: left;
        width: 100%;
        padding: 0px;
    }

    .industries img{
        width: 100%;
    }
    
    .industries p{
        color: #0C91BB;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }
}