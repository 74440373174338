@import url('https://fonts.googleapis.com/css2?family=Lateef:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    box-sizing: border-box;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.services{
    float: left;
    padding: 10px;
    margin-top: 5%;
}

.image{
    width: 50%;
}

.text{
    width: 50%;
}

@keyframes floatInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

.textCont{
    margin-left: 15%;
    animation: floatInFromLeft 0.5s ease forwards;
}

.textCont h2{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.textCont p{
    color: #FFF;
    text-align: justify;
    font-family: 'DM Sans', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    width: 542px;
}

.image img{
    border-radius: 39px;
    width: 643px;
    height: 477px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    object-position: 50% 50%;
    margin-bottom: 10%;
    border-radius: 5px;
}

.servicesCont{
    overflow: hidden;
}

.servicesCont h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 30px;
}

.background-div {
    width: 100%;
    height: 1050px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url('../../../assets/Rectangle12.png'), lightgray 50% / cover no-repeat;
    background-size: cover;
    background-position: center;
}

.container { 
    position: relative;
}

.iconCont{
    float: left;
    width: 20%;
    padding: 10px;
}

.svgContainerContent{
    width: 85%;
    height: 230px;
    flex-shrink: 0;
    background-color: rgba(163, 163, 163, 1);
    background-color: rgba(163, 163, 163, 0.4);
    margin: auto;
    padding: 10px;
    padding-top: 30px;
    padding-left: 100px;
    margin-top: 0%;
}

.svg-container {
    position: absolute;
    width: 200px;
    height: 200px;
    margin: auto;
}

.overlapping-image {
    position: absolute;
    top: 10%;
    left: 11%;
    width: 25%;
    height: 25%;
    object-fit: cover; 
    z-index: 1;
}

.svg-container .subcaption{
    text-align: center;
    margin-left: -50%;
    margin-top: -5%;
    color: #FFF;
    font-family: 'DM Sans', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px; /* 225% */
}

.background-svg{
    width: 104px;
    height: 110px;
}

.containerCenter h2{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: -35%;
}

@media screen and (max-width: 600px) {
    .services{
        float: left;
        padding: 10px;
        margin-top: 5%;
    }
    
    .image{
        width: 100%;
    }
    
    .text{
        width: 100%;
    }
    
    .textCont{
        margin-left: 5%;
    }
    
    .textCont h2{
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .textCont p{
        color: #FFF;
        text-align: justify;
        font-family: 'DM Sans', serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 150% */
        width: 350px;
    }
    
    .image img{
        border-radius: 39px;
        width: 350px;
        height: 477px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        object-position: 50% 50%;
        margin-bottom: 10%;
        border-radius: 5px;
    }
    
    .servicesCont{
        overflow: hidden;
    }
    
    .servicesCont h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin: 30px;
    }
    
    .background-div {
        width: 100%;
        height: 1300px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url('https://s3-alpha-sig.figma.com/img/c778/1d85/2fddf86646443652e8bc16e0bd978890?Expires=1694995200&Signature=DtaAWwMzbSa3nQQ4qKOu~7ufGmXFgcQLIqkJNya6Wv47xU7mDQO0p5v2sSQbHzPlyieZID4EIZLLtOasD9pKldsTCs8EHfBd8~PfuIVREXVLlWSO6EgsRXkzT2kDT6TMKL8laN3wfiyrRhMlEsvEkXooRUZQf0G~pJcewSCkl8oFnIMeadHpBJdVFjlCaaPO1zwroRmOFQe3U65WtEs0pW2Z2T0npQ17FZLUu4NlyHtbkNhT2uQ6kZIzXtjaUF21n9IhXPI8jMUCKDSnY9bykuS1tVghhSYmrLm9KeVTdCCNOq4O4wRVtsP3BXRuLkHsVxzZqSh8ShwHvXY3VCDs5Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'), lightgray 50% / cover no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .container { 
        position: relative;
    }
    
    .iconCont{
        float: left;
        width: 20%;
        padding: 10px;
    }
    
    .background-svg{
        width: 90px;
        height: 90px;
    }

    .svgContainerContent{
        width: 100%;
        height: 230px;
        flex-shrink: 0;
        background-color: rgba(163, 163, 163, 1);
        background-color: rgba(163, 163, 163, 0.4);
        margin: auto;
        padding: 10px;
        padding-top: 30px;
        padding-left: 0px;
        margin-top: 0%;
    }
    
    .svg-container {
        position: absolute;
        width: 200px;
        height: 200px;
        margin: auto;
    }
    
    .overlapping-image {
        position: absolute;
        top: 8%;
        left: 11%;
        width: 20%;
        height: 20%;
        object-fit: cover; 
        z-index: 1;
    }
    
    .svg-container .subcaption{
        text-align: center;
        margin-left: -15%;
        margin-top: 0%;
        color: #FFF;
        font-family: 'DM Sans', serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 225% */
        width: 130px;
    }
    
    .containerCenter h2{
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-top: -35%;
    } 
}