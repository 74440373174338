.detailsBody{
    max-width: 80%;
    margin: auto;
}

.servicescolumn {
    float: left;
    width: 50%;
    padding: 20px;
}

.servicescolumn img{
    border-radius: 0px 30px;
    height: 420px;
    flex-shrink: 0;
    width: 100%;
    margin-left: 10%;
    margin-top: 3%;
}

.servicescolumn h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.servicescolumn p{
    color: #17252A;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 250% */
}

.servicescolumn span{
    color: #0C91BB;
    text-align: justify;
    font-family: 'DM Sans', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 166.667% */
}

.servicescolumn a{
    text-decoration: none;
}

.servicescolumn span svg{
    margin-left: 10px;
}

@media screen and (max-width: 600px) {
    .detailsBody{
        max-width: 100%;
        margin: auto;
    }
    
    .servicescolumn {
        float: left;
        width: 100%;
        padding: 20px;
    }
    
    .servicescolumn img{
        border-radius: 0px 30px;
        height: 360px;
        flex-shrink: 0;
        width: 100%;
        margin-left: 0%;
        margin-top: 3%;
    }
    
    .servicescolumn h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .servicescolumn p{
        color: #17252A;
        text-align: justify;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px; /* 250% */
    }

    .servicescolumn a{
        text-decoration: none;
    }
    
    .servicescolumn span{
        color: #0C91BB;
        text-align: justify;
        font-family: 'DM Sans', serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 166.667% */
    }
    
    .servicescolumn span svg{
        margin-left: 10px;
    }
}