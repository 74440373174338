.coreValuesBody {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    text-align: center;
    margin-top: 2%;
}

.coreValuesBody div > * {
    margin: 1rem 1rem;
}

@keyframes spin {
    from {
    transform: translate(-50%, -50%) rotate(0);
    }
    to {
    transform: translate(-50%, -50%) rotate(360deg);
    }
}

.rotating-border {
    --border-radius: 4rem;
    --border-size: 1rem;
    --border-bg: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
    --padding: 1rem;
    position: relative;
    overflow: hidden;
    padding: calc(var(--padding) + var(--border-size));
    border-radius: var(--border-radius);
    display: inline-block;
}

.rotating-border::before {
    content: '';
    display: block;
    background: var(--border-bg);
    width: calc(100% * 1.41421356237);
    padding-bottom: calc(100% * 1.41421356237);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: -2;
    animation: spin 5s linear infinite;
}

.rotating-border--reverse::before {
    animation-direction: reverse;
}

.rotating-border::after {
    content: '';
    position: absolute;
    inset: var(--border-size);
    background: white;
    z-index: -1;
    border-radius: calc(var(--border-radius) - var(--border-size));
}

.rotating-border--blue {
    --border-radius: 100%;
    --border-bg: conic-gradient(
    #010C6C 0.1666turn,
    #010C6C 0.1666turn 0.3333turn,
    #010C6C 0.3333turn 0.5turn,
    #010C6C 0.5turn 0.6666turn,
    #FFF 0.6666turn 0.8333turn,
    #FFF 0.8333turn
    );
    width: 9.5rem;
    height: 9.5rem;
    text-align: center;
    padding-top: 35%;
    color: #17252A;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.floating-text {
    font-size: 24px;
    color: #3498db;
    animation: floatText 3s ease;
}

@keyframes floatText {
    0%, 100% {
        transform: translateX(-100px);
    }
    50% {
        transform: translateX(20px);
    }
}

.aboutCont{
    max-width: 90%;
    margin: auto;
}

.aboutColumn{
    width: 50%;
    float: left;
    padding: 10px;
}

.aboutColumn h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10%;
}

.aboutColumn p{
    color: #17252A;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 250% */
    width: 90%;
}

.aboutColumn img{
    width: 100%;
}

.missionContainer h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 8%;
}

.missionContainer .leftMissionImg{
    float: left;
    margin-left: 5%;
}

.missionContainer .rightMissionImg{
    float: right;
    margin-left: 5%;
}

.missionContainer .missionImage{
    margin-top: 3%;
}

.missionContainer .missionImageRight{
    margin-bottom: 20%;
    margin-top: -4%;
}

.missionContainer span{
    color: #17252A;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: 6px solid #010C6C;
    margin-top: 15%;
}

.missionContainer p{
    color: #17252A;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 250% */
    width: 80%;
}

.missionText{
    padding-top: 3%;
    padding-left: 30%;
}

.missionTextRight{
    margin-top: 17%;
    padding-left: 10%;
    width: 100%;
}

.missionTextRight p{
    width: 60%;
}

.missionTextRight img{
    margin-top: -18%;
}

.missionImageLeft{
    margin-top: -15%;
    margin-bottom: 5%;
}

.coreValuesBodyHead{
    color: #17252A;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px; /* 250% */
    border-bottom: 4px solid #010C6C;
    width: 13%;
    margin-left: 43.5%;
    margin-right: 43.5%;
}

.jobPost h1{
    color: #17252A;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 12%;
    margin-top: 2%;
}

.jobPost p{
    color: #17252A;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 250% */
    margin-left: 12%;
}

.jobPost h2{
    color: #17252A;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.jobInline{
    display: inline-flex;
    margin-left: 8%;
    margin-right: 10%;
    width: 70%;
    margin-bottom: 5%;
}

.jobSquare{
    padding: 30px 160px;
    border-radius: 10px;
    border: 1px solid #010C6C;
    background: #E9E8E8;
    margin-left: 4%;
}

.jobApply{
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 50px;
    padding-left: 150px;
    padding-right: 150px;
    width: 80%;
    margin: auto;
}

.jobApply h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 5%;
}

.jobApply input[type=text],input[type=email],textarea {
    width: 100%;
    padding: 15px 20px;
    margin: 8px 0;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 3%;
    margin-top: 1.5%;
}

.jobApply select{
    width: 25%;
    padding: 15px 20px;
    margin: 8px 0;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 2%;
    margin-top: 1%;
    font-size: 18px;
}

.jobApply input[type=submit] {
    width: 20%;
    margin-left: 40%;
    margin-right: 40%;
    background: #0C91BB;
    color: #FFF;
    font-family: 'DM Sans', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.1px;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.jobApply input[type=submit]:hover {
    background-color: #FFF;
    border: 1px solid #0C91BB;
    color: #0C91BB;
}

.jobApply textarea{
    height: 200px;
}

.jobApply label{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (max-width: 600px) {
    .rotating-border--blue {
        --border-radius: 150%;
        --border-bg: conic-gradient(
        #010C6C 0.1666turn,
        #010C6C 0.1666turn 0.3333turn,
        #010C6C 0.3333turn 0.5turn,
        #010C6C 0.5turn 0.6666turn,
        #FFF 0.6666turn 0.8333turn,
        #FFF 0.8333turn
        );
        width: 9.5rem;
        height: 9.5rem;
        text-align: center;
        padding-top: 35%;
        color: #17252A;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .aboutColumn{
        width: 100%;
        float: left;
        padding: 10px;
    }

    .aboutColumn h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 10%;
    }

    .aboutColumn p{
        color: #17252A;
        text-align: justify;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px; /* 250% */
        width: 90%;
    }

    .aboutColumn img{
        width: 100%;
    }

    .missionContainer h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .missionContainer .leftMissionImg{
        float: left;
        margin-left: 5%;
        display: none;
    }

    .missionContainer .rightMissionImg{
        float: right;
        margin-left: 5%;
        display: none;
    }

    .missionContainer .missionImage{
        margin-top: 3%;
    }

    .missionContainer .missionImageRight{
        margin-bottom: 20%;
        margin-top: -4%;
    }

    .missionContainer span{
        color: #17252A;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-bottom: 3px solid #010C6C;
        margin-top: 15%;
    }

    .missionContainer p{
        color: #17252A;
        text-align: justify;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px; /* 250% */
        width: 80%;
    }

    .missionText{
        padding-top: 3%;
        padding-left: 0%;
    }

    .missionText p{
        width: 100%;
    }

    .missionTextRight{
        margin-top: 15%;
        padding-left: 0%;
        width: 100%;
    }

    .missionTextRight p{
        width: 100%;
    }

    .missionTextRight img{
        margin-top: -25%;
    }

    .missionImageLeft{
        margin-top: -15%;
        margin-bottom: 5%;
    }

    .coreValuesBodyHead{
        color: #17252A;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px; /* 250% */
        border-bottom: 4px solid #010C6C;
        width: 30%;
        margin-left: 35%;
        margin-right: 35%;
    }

    .jobPost h1{
        color: #17252A;
        font-family: 'Roboto', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 6%;
        margin-top: 5%;
    }

    .jobPost p{
        color: #17252A;
        text-align: justify;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 250% */
        margin-left: 6%;
    }

    .jobPost h2{
        color: #17252A;
        font-family: 'Roboto', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }

    .jobInline{
        display: block;
        margin-left: 5%;
        margin-bottom: 5%;
    }

    .jobSquare{
        padding: 30px 100px;
        border-radius: 10px;
        border: 1px solid #010C6C;
        background: #E9E8E8;
        margin-left: 0%;
        margin-bottom: 4%;
    }

    .jobApply{
        border-radius: 5px;
        background-color: #f2f2f2;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        margin: auto;
    }

    .jobApply h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom: 5%;
    }

    .jobApply input[type=text],input[type=email],textarea {
        width: 100%;
        padding: 15px 20px;
        margin: 8px 0;
        display: block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        margin-bottom: 3%;
        margin-top: 1.5%;
    }

    .jobApply select{
        width: 40%;
        padding: 15px 20px;
        margin: 8px 0;
        display: block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        margin-bottom: 2%;
        margin-top: 1%;
        font-size: 18px;
    }

    .jobApply input[type=submit] {
        width: 40%;
        margin-left: 30%;
        margin-right: 30%;
        background: #0C91BB;
        color: #FFF;
        font-family: 'DM Sans', serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.1px;
        padding: 14px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 5%;
    }

    .jobApply input[type=submit]:hover {
        background-color: #FFF;
        border: 1px solid #0C91BB;
        color: #0C91BB;
    }

    .jobApply textarea{
        height: 200px;
    }

    .jobApply label{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}