.body{
    padding: 10px;
}

.productsHeader{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.aboutBody{
    max-width: 100%;
    margin: auto;
    padding: 0px;
}

.contactSubscribe{
    margin-top: 37%;
}

.productsSend{
    margin-top: 5%;
}

@media screen and (max-width: 600px) {
    .body{
        padding: 0px;
    }
}