@import url('https://fonts.googleapis.com/css2?family=Lateef:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

* {
    box-sizing: border-box;
}

.slideshow-container {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: scroll;
    background-color: #ccc
}

/* 
.slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
} */

.slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease;
}

@keyframes slideIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    animation: slideIn 0.5s ease forwards;
}

.slide.active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.dot-container {
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.dot {
    width: 15px;
    height: 15px;
    background-color: #FFF;
    border-radius: 50%;
    display: inline-block;
    margin: 0 15px;
    cursor: pointer;
}

.dot.active {
    background-color: #0C91BB;
}

.slideContent{
    font-family: 'Lateef', serif;
    height: 100%;
    width: 100%;
    bottom: 0%;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
}

.slideContentText{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
}

.slideContentText h1{
    color: #FFF;
    font-family: 'Lateef', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 10.73px;
    width: 100%;
}

.slideContentText h2{
    color: #FFF;
    font-family: 'Lateef', serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 5.425px;
    width: 90%;
    margin: auto;
    padding-top: 1%;
}

.slideContentText h5{
    color: #FFF;
    text-align: center;
    font-family: 'Lateef', serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 1007.641px;
    flex-shrink: 0;
    margin-left: 5%;
    margin-top: -1%;
}

.slideContentText button{
    border-radius: 10px;
    background: #0C91BB;
    width: 211px;
    height: 60px;
    flex-shrink: 0;
    border: none;
    color: #FFF;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.1px;
    margin-top: -3%;
}

.slideContentText button:hover{
    background: #010C6C;
}

.cardContainer{
    max-width: 80%;
    margin: auto;
}

.card{
    background: rgba(163, 163, 163, 0.4);
    width: 170px;
    height: 200px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.card:hover{
    background: #010C6C;
}

.card svg{
    margin-top: 35%;
}

.card h4{
    color: #FFF;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 0%;
}

.column {
    float: left;
    width: 20%;
    padding: 0px;
    margin-top: 2%;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 600px) {
    .slideshow-container {
        position: relative;
        width: 100%;
        height: 95vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    
    .slide {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        position: absolute;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), lightgray 50% / cover no-repeat;
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        object-position: 0% 0%;
    }
    
    .slide.active {
        opacity: 1;
    }
    
    .dot-container {
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .dot {
        width: 15px;
        height: 15px;
        background-color: #FFF;
        border-radius: 50%;
        display: inline-block;
        margin: 0 15px;
        cursor: pointer;
    }
    
    .dot.active {
        background-color: #0C91BB;
    }
    
    .slideContent{
        position: relative;
        margin-top: 100%;
    }
    
    .slideContentText{
        margin: 0;
        position: absolute;
        top: 0%;
        left: 46.5%;
        width: 100%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    
    .slideContentText h2{
        color: #FFF;
        text-align: center;
        font-family: 'Lateef', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 420px;
        margin: auto;
        margin-left: 3%;
    }
    
    .card{
        background: rgba(163, 163, 163, 0.4);
        width: 130px;
        height: 140px;
        flex-shrink: 0;
        color: #FFF;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
    
    .card svg{
        margin-top: 20%;
    }
    
    .card h4{
        color: #FFF;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-bottom: -5%;
        margin-top: 0%;
    }
    
    .card p{
        color: #FFF;
        text-align: center;
        font-family: 'Roboto' sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .column {
        float: left;
        width: 33.333%;
        padding: 20px;
    }
}