.sendMessageCont{
    background-color: #EEF0FE;
    width: 75%;
    margin: auto;
    border-radius: 10px;
    padding: 30px;
    margin-top: 3%;
}

.sendMessageCont h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.sendMessageForm {  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 30px;
}

.sendMessageForm input[type=email], input[type=text], input[type=number] {
    vertical-align: middle;
    margin: 5px 30px 5px 0;
    padding: 15px 10px 15px 20px;
    background-color: #fff;
    border: none;
    width: 46.5%;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    margin-bottom: 2%;
}

.sendMessageForm input::placeholder{
    color: #A3A3A3;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.sendMessageForm input:focus{
    outline: none;
}

.sendMessageForm textarea{
    vertical-align: middle;
    margin: 5px 30px 5px 0;
    padding: 15px 10px 15px 20px;
    background-color: #fff;
    border: none;
    width: 96.5%;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-top: 2%;
    height: 200px;
}

.sendMessageForm textarea::placeholder{
    color: #A3A3A3;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.sendMessageForm button{
    background: #0C91BB;
    padding: 15px 30px;
    border: none;
    color: #FFF;
    margin: auto;
    margin-top: 4%;
    font-size: 18px;
}

@media screen and (max-width: 600px) {
    .sendMessageCont{
        background-color: #EEF0FE;
        width: 90%;
        margin: auto;
        border-radius: 10px;
        padding: 20px;
        margin-top: 5%;
    }
    
    .sendMessageCont h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }
    
    .sendMessageForm {  
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: 0px;
        margin-left: 5%;
    }
    
    .sendMessageForm input[type=email], input[type=text] {
        vertical-align: middle;
        margin: 5px 30px 5px 0;
        padding: 15px 10px 15px 20px;
        background-color: #fff;
        border: none;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-weight: 400;
        display: flex;
    }
    
    .sendMessageForm input::placeholder{
        color: #A3A3A3;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }
    
    .sendMessageForm input:focus{
        outline: none;
    }
    
    .sendMessageForm textarea{
        vertical-align: middle;
        margin: 5px 30px 5px 0;
        padding: 15px 10px 15px 20px;
        background-color: #fff;
        border: none;
        width: 96.5%;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-weight: 400;
        margin-top: 2%;
        height: 200px;
    }
    
    .sendMessageForm textarea::placeholder{
        color: #A3A3A3;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }
    
    .sendMessageForm button{
        background: #0C91BB;
        padding: 15px 30px;
        border: none;
        color: #FFF;
        margin: auto;
        margin-top: 4%;
        font-size: 18px;
    }    
}