@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

.subscribeContainer{
    background: rgba(1, 12, 108, 0.5);
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin-top: 5%;
    margin-bottom: 2%;
}

.subscribeContainer h1{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 5.425px;
    text-align: center;
}

.subscribeForm {  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-left: 20%;
    margin-right: 10%;
    padding: 20px;
}

.subscribeForm input[type=email] {
    vertical-align: middle;
    margin: 5px 0px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: none;
    width: 55%;
    height: 60px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.375px;
}

.subscribeForm input::placeholder{
    color: rgba(163, 163, 163, 0.80);
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.375px;
}

.subscribeForm button {
    padding: 16px 50px;
    background-color: #0C91BB;
    border: 1px solid #0C91BB;
    cursor: pointer;
    color: #FFF;
    font-family: 'DM Sans', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.1px;
}

@media screen and (max-width: 600px) {
    .subscribeContainer{
        background: rgba(1, 12, 108, 0.5);
        justify-content: center;
        align-items: center;
        padding: 30px;
        margin-top: 5%;
        margin-bottom: 2%;
    }
    
    .subscribeContainer h1{
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 3px;
        text-align: center;
    }
    
    .subscribeForm {  
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-left: 0%;
        margin-right: 0%;
        padding: 0px;
    }
    
    .subscribeForm input[type=email] {
        vertical-align: middle;
        margin: 5px 0px 5px 0;
        padding: 10px;
        background-color: #fff;
        border: none;
        width: 100%;
        height: 50px;
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 4.375px;
    }
    
    .subscribeForm input::placeholder{
        color: rgba(163, 163, 163, 0.80);
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 4.375px;
    }
    
    .subscribeForm button {
        padding: 10px 20px;
        background-color: #0C91BB;
        border: 1px solid #0C91BB;
        cursor: pointer;
        color: #FFF;
        font-family: 'DM Sans', serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.1px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3%;
    }    
}