.contactCol{
    width: 33.3333%;
    float: left;
    padding: 0px;
}

.contactCont{
    max-width: 85%;
    margin: auto;
}

.contactCard{
    border-radius: 30px 30px 0px 0px;
    background: #FFF;
    box-shadow: 5px 10px 10px 4px rgba(0, 0, 0, 0.25);
    height: 340px;
    z-index: 9;
    margin: 20px;
    position: relative;
    padding: 0px;
    text-align: center;
}

.contactCard img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    padding-top: 15%;
    background-color: #FFF;
}

.contactCard h1{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 7%;
}

.contactCard .link{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;
    line-height: 30px;
}

.contactCard p{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 60%;
    margin: auto;
    line-height: 25px;
    text-align: left;
    margin-top: 10%;
}

.contactCard .number{
    color: #010C6C;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 60%;
    margin: auto;
    line-height: 30px;
    text-align: center;
    margin-top: 6%;
}

.contactCard .borderedImg{
    padding-top: 15%;
}

.contactCard .borderedImg img{
    border: 5px solid rgba(9, 105, 163, 0.65);
    border-radius: 50%;
    padding: 15px;
}

.mapContainer{
    border-top: 100px solid #010C6C;
    padding: 0px;
    position: absolute;
    margin-top: 10%;
    width: 100%;
}

.mapContainer iframe{
    width: 100%;
    height: 600px;
}

@media screen and (max-width: 600px) {
    .contactCol{
        width: 100%;
        float: left;
        padding: 0px;
    }    

    .contactCont{
        max-width: 100%;
        margin: auto;
    }
    
    .contactCard{
        border-radius: 30px 30px 0px 0px;
        background: #FFF;
        box-shadow: 5px 10px 10px 4px rgba(0, 0, 0, 0.25);
        height: 150px;
        z-index: 9;
        margin: 5px;
        position: relative;
        padding: 0px;
        text-align: center;
        width: 90%;
        margin-left: 5%;
    }
    
    .contactCard img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 30%;
        padding-top: 15%;
        background-color: #FFF;
        display: none;
    }
    
    .contactCard h1{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-top: 0%;
        padding-top: 10%;
    }
    
    .contactCard .link{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        text-decoration-line: underline;
        line-height: 25px;
    }
    
    .contactCard p{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 60%;
        margin: auto;
        line-height: 25px;
        text-align: left;
        margin-top: 5%;
    }
    
    .contactCard .number{
        color: #010C6C;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 60%;
        margin: auto;
        line-height: 30px;
        text-align: center;
        margin-top: 6%;
    }
    
    .contactCard .borderedImg{
        padding-top: 15%;
        display: none;
    }
    
    .contactCard .borderedImg img{
        border: 5px solid rgba(9, 105, 163, 0.65);
        border-radius: 50%;
        padding: 15px;
    }
    
    .mapContainer{
        border-top: 100px solid #010C6C;
        padding: 0px;
        position: absolute;
        margin-top: 10%;
        width: 100%;
    }
    
    .mapContainer iframe{
        width: 100%;
        height: 450px;
    }
}